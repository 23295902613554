<template>
  <div>
    <div class="content-header">
      <div class="title-1">
        <router-link to="/registration"
          ><img src="../assets/back_arrow.svg" /><span style="color: #44D62C;"
            >Regristration or login</span
          ></router-link
        >
      </div>
    </div>

    <div class="single-games">
      <div class="forgot-pass-1">
        <div id="errorMessage" style="color:red;">&nbsp;</div>
        <form action="#">
          <div class="content-forgot">
            <div id="content1">
              <input
                type="password"
                v-model="newPassword"
                class="form-control"
                placeholder="Type your new password"
              />
            </div>
            <div id="content2">
              <input
                type="password"
                class="form-control"
                v-model="confirmPassword"
                placeholder="Confirm Password"
              />
            </div>
          </div>
        </form>
      </div>
      <button type="button" class="submit-btn1" @click="resetPassword" id="otp">
        Submit
      </button>
    </div>
    <footer>
      <div class="main-footer">
        <div class="social-icon"></div>
      </div>
    </footer>
    <div class="modal-background" id="regModal">
      <div class="modal-content">
        <span class="close" @click="hideModal('regModal')"></span>
        <h4>Successful!</h4>
        <h5>Your password has been changed</h5>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewPass",
  data: () => {
    return {
      host: null,
      newPassword: "",
      confirmPassword: ""
    };
  },
  created() {
    this.host = this.$root.url();
    if (
      localStorage.getItem("phone") ||
      !localStorage.getItem("temporaryPhoneStore")
    ) {
      this.$router.push({ name: "Registration" });
    }
  },
  methods: {
    showModal: function(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
    },
    hideModal: function(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    },
    resetPassword: function() {
      let vm = this;
      $("#otp").hide();
      let newPassword = this.newPassword;
      let confirmPassword = this.confirmPassword;
      let userPhone = localStorage.getItem("temporaryPhoneStore");
      if (newPassword !== "" && confirmPassword !== "" && userPhone !== "") {
        $("#errorMessage").html("<p>validation successful</p>");
        const path = "passwordReset";
        const url = `${this.host}/${path}?phone=${userPhone}&password=${newPassword}&password_confirmation=${confirmPassword}`;
        axios
          .get(url)
          .then(function(response) {
            if (response.data.success === true) {
              localStorage.clear();
              $("#errorMessage").html(
                "<p>Your password has been successfully reset</p>"
              );
              return true;
            } else {
              $("#otp").show();
              $("#errorMessage").html(response.data.messages);
              return true;
            }
          })
          .catch(() => {
            $("#otp").show();
            $("#errorMessage").html(
              "<p>something goes to wrong,please try again later</p>"
            );
            return false;
          })
          .finally(() => {
            if (!localStorage.getItem("temporaryPhoneStore")) {
              this.showModal("regModal");
              setTimeout(function() {
                vm.$router.push({ name: "Registration" });
              }, 2000);
            }
          });
      } else {
        $("#otp").show();
        $("#errorMessage").html("<p>please fill up all fields</p>");
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
